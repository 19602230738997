.card-detalhe-parcela {
    display: flex;
    height: 50px;
    margin-top: 15px;
    flex-direction: row;
    border: 1px solid #414141;
    justify-content: space-between;
    padding: 0px 5%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 2px 0px rgba(65, 65, 65, 0.33);
    -moz-box-shadow: 0px 3px 2px 0px rgba(65, 65, 65, 0.33);
    box-shadow: 0px 3px 2px 0px rgba(65, 65, 65, 0.33);
}
.card-detalhe-parcela:first-child {
    margin-top:0;
}

.card-detalhe-parcela-numero>span:last-of-type {
    font-size: 30px;
    font-weight: bold;
    color: #051773;
    line-height: 50px;
}

.wrap-valor-detalhe-parcela {
    display: flex;
    flex-direction: column;
}

.card-detalhe-parcela-numero {
    display: flex;
}

.wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
    font-size: 13px;
    color: grey;
    font-weight: normal;
    letter-spacing: normal;
}

.card-detalhe-parcela-numero>span:first-of-type {
    margin: auto;
    margin-right: 10px;
}

.wrap-valor-detalhe-parcela>div {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: auto 0px;
}

@media (max-width: 520px) {

    .card-detalhe-parcela-numero>span:first-of-type {
        margin: auto;
        margin-right: 0;
        width: 45px;
    }

    .card-detalhe-parcela-numero>span:last-of-type {
        font-size: 20px;
        font-weight: bold;
        color: #051773;
        line-height: 50px;
    }

    .card-detalhe-parcela>span {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div {
        font-size: 14px;
    }
}